import React from 'react'
import '../../assets/css/login.css'
import LoginImageSection from './LoginImageSection'
import LoginForm from './LoginForm'

const Login = () => {

  return (
    <div className='login__container'>
        <LoginImageSection />
        <LoginForm />
    </div>
  )
}

export default Login