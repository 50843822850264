import React, { useContext, useEffect, useState } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import UserBox from "../../components/user/UserBox";
import { Header, Navbar, ClassesList, SubSidebar } from "../../layouts";
import "../../assets/css/missing.css";
import { students } from "../../data/List";
import MissingBox from "./MissingBox";
import { ClassContext } from "../../contexts/ClassContext";
import { GetAbsencesClasse } from "../../API/react_query/react_query";
import JustificationPopup from "./JustificationPopup";
import Loading from "../../layouts/Loading";
import {
  groupByDate,
  groupByDateAll,
  supprimerDoublonsAbsences,
} from "../../utils/fonctions";

const Missings = () => {
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);

  const [isOpen, setIsOpen] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(0);
  const [absenceEleve, setAbsenceEleve] = useState([]);

  const {
    isError: isErrorAbs,
    isLoading: isLoadingAbs,
    error: errorAbs,
    refetch: refetchAbs,
    isFetching: isFetchingAbs,
    data: dataAbs,
  } = GetAbsencesClasse(currentClass, anneeScol);

  const openJustificationPopup = (studentId) => {
    setIsOpen(true);
    setCurrentStudentId(studentId);
    const listAbPouEleve = dataAbs.absences.filter(
      (absence) => absence.eleve.id === studentId
    );
    setAbsenceEleve(groupByDateAll(listAbPouEleve));
  };

  const closeJustificationPopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    refetchAbs();
  }, [isOpen]);

  const users = [];

  return (
    <>
      <Navbar />
      <Header title={"Absences et retards de la " + currentClassName} />
      <ClassesList />

      <main>
        <SubSidebar
          title="Absences et retards"
          filterList={["Cette semaine", "Tout"]}
        />

        <div className="main-content missings grid-2">
          {dataAbs && dataAbs.absences && dataAbs.absences.length > 0 ? (
            supprimerDoublonsAbsences(dataAbs.absences).map((user) => (
              <MissingBox
                key={user.id}
                user={user.eleve}
                matier={user.cours.matiere.libelle}
                heur_debut={user.cours.heure_debut.slice(0, -3)}
                heur_fin={user.cours.heure_fin.slice(0, -3)}
                justifyFn={openJustificationPopup}
              />
            ))
          ) : (
            <div className="vide medium">Aucune absence pour le moment.</div>
          )}
        </div>

        {isLoadingAbs || (isFetchingAbs && <Loading size="medium" />)}
      </main>

      {isOpen && (
        <JustificationPopup
          onClose={closeJustificationPopup}
          studentId={absenceEleve}
        />
      )}
    </>
  );
};

export default Missings;
