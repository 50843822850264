import React, { useContext, useState } from "react";
import PopupForm from "../../components/ui/modal/PopupForm";
import DropdownList from "../../components/form/DropdownList";
import {
  IoTransgenderOutline,
  IoPersonOutline,
  IoPhonePortraitOutline,
  IoCalendarOutline,
  IoHomeOutline,
  IoEarthOutline,
  IoGitNetworkOutline,
} from "react-icons/io5";
import InputField from "../../components/form/InputField";
import { verifierValidite } from "../../utils/fonctions";
import ErrorBox from "../../components/ui/ErrorBox";
import { ClassContext } from "../../contexts/ClassContext";
import { addEleve } from "../../API/fonctions/addEleve";
import { wait } from "@testing-library/user-event/dist/utils";

const AddStudentPopup = ({ onClose }) => {
  const [sexe, setSexe] = useState("");
  const [nomComplet, setNomComplet] = useState("");
  const [nomCompletTuteur, setNomCompletTuteur] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [numeTeleTuteur, setNumeTeleTuteur] = useState("");
  const [messageError, setMessageError] = useState();
  const [process, setProcess] = useState(false);
  const [nationnalite, setNationnalite] = useState("Sénégalaise");
  const [lieuxDeNaissance, setLieuxDeNiassance] = useState();
  const [lienDeParente, setLienDeParente] = useState("Tuteur");
  const [addresTuteur, setaddresTuteur] = useState("dakar");
  const { currentClass, anneeScol } = useContext(ClassContext);

  const handleSubmit = async () => {
    if (
      verifierValidite(
        nomComplet,
        nomCompletTuteur,
        numeTeleTuteur,
        nationnalite,
        lieuxDeNaissance,
        lienDeParente,
        addresTuteur,
        setMessageError
      )
    ) {
      const body = [
        {
          nom_complet: nomComplet,
          login: nomComplet,
          matricule: "123456",
          date_naissance: dateNaissance,
          lieu_naissance: lieuxDeNaissance,
          password: "1234", //default
          sexe: sexe,
          nationalite: "Sénégalaise",
          adresse: "Adresse",
          telephone: null, // numeTeleTuteur,
          email: null, //nullable
          nom_complet_tuteur: nomCompletTuteur,
          num_tel_tuteur: numeTeleTuteur,
          addresse_tuteur: "Adresse",
          email_tuteur: null, //nullable
          profession: "Profession",
          lien_de_parente: "Tuteur",
          login_tuteur: numeTeleTuteur, //numtel tuteur
          pass: "1234", //par defaut,
          classe_id: currentClass,
          annee_id: anneeScol,
        },
      ];
      addEleve(body, onClose, setMessageError, setProcess);
    }
  };

  return (
    <PopupForm
      onClose={onClose}
      title="Inscrire un élève"
      onSubmit={handleSubmit}
    >
      {messageError && <ErrorBox message={messageError} />}
      <InputField
        placeholder="Nom complet de l'élève"
        value={nomComplet}
        setValue={setNomComplet}
        name="nomComoplet"
        icon={<IoPersonOutline />}
      />
      <InputField
        placeholder="date de naissance  "
        type="date"
        // value={dateNaissance}
        setValue={setDateNaissance}
        icon={<IoCalendarOutline />}
      />
      <InputField
        placeholder="lieu de naissance"
        // value={lieuxDeNaissance}
        setValue={setLieuxDeNiassance}
        icon={<IoHomeOutline />}
      />
      <DropdownList
        icon={<IoTransgenderOutline />}
        items={[
          { id: "M", content: "Garçon" },
          { id: "F", content: "Fille" },
        ]}
        setValue={setSexe}
        value={sexe}
      />
      <InputField
        placeholder="Nom complet du tuteur "
        // value={nomCompletTuteur}
        setValue={setNomCompletTuteur}
        icon={<IoPersonOutline />}
      />

      <InputField
        placeholder="Numéro de téléphone du tuteur"
        type="number"
        // value={numeTeleTuteur}
        setValue={setNumeTeleTuteur}
        icon={<IoPhonePortraitOutline />}
        name="nomComoplet"
      />
    </PopupForm>
  );
};

export default AddStudentPopup;
