//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken, uploadTokenPUT } from "./upload";

const endpoint = "report/cours";
const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function repportCours(body, onClose) {
  //  // console.log(body)
  const reponse = await uploadTokenPUT(endpoint, body, headers);
  // console.log(reponse)
  const errorDetails = await reponse.json();
  console.error("Erreur serveur:", reponse.status, errorDetails);
  onClose();

  if (reponse.ok == true) {
  }
}

export { repportCours };
