import React, { useEffect, useState } from "react";
import {
  IoArrowDownSharp,
  IoChevronDownOutline,
  IoPencilSharp,
} from "react-icons/io5";
import UserBox from "../../components/user/UserBox";
import DropdownFilter from "../../components/ui/DropdownFilter";
import NoteBox from "./NoteBox";
import EditMarkPopup from "./EditMarkPopup";

const NotesList = ({ openModal, results, bulletins, refetcFn }) => {
  // triage...[[[]]]

  const [isEditing, setIsEditing] = useState(false);
  const [studentToEdit, setStudentToEdit] = useState();

  useEffect(() => {
    refetcFn();
  }, [isEditing]);

  const openEditionPopup = (std) => {
    setIsEditing(true);
    setStudentToEdit(std);
  };

  const closeEditionPopup = () => {
    setIsEditing(false);
  };

  return (
    <div className="notes">
      {results.length > 0 ? (
        results.map((student, index) => (
          <NoteBox
            student={student}
            key={index}
            isBulletin={bulletins}
            editFn={openEditionPopup}
          />
        ))
      ) : (
        <div className="vide small">
          Les notes ne sont pas encore disponible
        </div>
      )}

      {isEditing && (
        <EditMarkPopup onClose={closeEditionPopup} student={studentToEdit} />
      )}
    </div>
  );
};

export default NotesList;
