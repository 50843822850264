import React, { useState, useContext } from "react";
import "../../assets/css/students.css";
import { Header, Navbar, ClassesList, SubSidebar } from "../../layouts";
import StudentInfos from "./StudentInfos";
import UserBox from "../../components/user/UserBox";
import { ClassContext } from "../../contexts/ClassContext";
import { useQuery } from "@tanstack/react-query";
import DisplayStudents from "./DisplayStudents";
import { getData } from "../../API/fonctions/getData";
import { accountService } from "../../API/service/account.service";
import Loading from "../../layouts/Loading";
import AddStudentPopup from "./AddStudentPopup";
import AddButton from "../../components/ui/button/AddButton";

const Students = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filterTerm, setFilterTerm] = useState("");
  const [searchData, setSearchData] = useState("");
  const [studentClicked, setStudentClicked] = useState();

  const toggleAddPopup = () => {
    setIsAddOpen((prev) => !prev);
    // refetch();
  };

  const { currentClassName, currentClass } = useContext(ClassContext);

  // console.log("la classe actuelle ", currentClass);
  const token = accountService.getToken();

  const fetchStudents = async () => {
    const data = getData("classe/" + currentClass, token);
    return data;
  };

  const { isError, isLoading, error, refetch, isFetching, data } = useQuery({
    queryKey: ["eleves", currentClass],
    queryFn: fetchStudents,
    staleTime: 1000 * 60 * 60,
  });

  // // console.log("les elevesssssssssssss", data);

  // if (!currentClass) return <div> ajouter une classe </div>;

  const filterStudents = (category) => {
    // WE PUT THE REVERSE VALUE FOR HELPING THE FILER FUNCTION IN THE DISPLAYSTUDENTS COMPONENT
    if (category == "Garçons") {
      setFilterTerm("M");
    } else if (category == "Filles") {
      setFilterTerm("F");
    } else {
      setFilterTerm("");
    }
  };

  const openModal = (student) => {
    setIsOpen(true);
    setStudentClicked(student);
  };

  const closeModal = () => {
    setIsOpen(false);
    //refetch();
  };
  const closeModalRefreche = () => {
    setIsOpen(false);
    refetch();
  };

  const searchFn = (term) => {
    if (term == "") {
      setSearchData("");
    } else {
      const newData = [
        ...[...new Set(data.eleves.map((item) => ({ eleves: item.eleve })))],
      ].filter((d) => {
        // // console.log("test",data)
        return d.prenom.toLowerCase().includes(term.toLowerCase());
      });
      setSearchData(newData);
      // // console.log(newData);
      // // console.log("Term : " + term);
    }
  };

  return (
    <>
      <Navbar />
      <Header title={"Liste des élèves de la " + currentClassName} />
      <ClassesList refetchFn={refetch} />

      <main>
        <SubSidebar
          filterList={["Tout", "Filles", "Garçons"]}
          filterFn={filterStudents}
          searchFn={searchFn}
        />
        {currentClass ? (
          <div className="main-content students">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <DisplayStudents
                students={
                  searchData === ""
                    ? [
                        ...new Set(
                          data.eleves
                            .filter(
                              (item) =>
                                filterTerm === "" ||
                                item.eleve.sexe === filterTerm
                            )
                            .map((item) => ({
                              eleves: { ...item.eleve, tuteur: item.tuteur },
                            }))
                        ),
                      ]
                    : searchData
                }
                openModal={openModal}
                filterTerm={filterTerm}
              />
            )}

            {isOpen && (
              <StudentInfos
                studentClicked={studentClicked}
                closeModal={closeModal}
                closeModalRefreche={closeModalRefreche}
              />
            )}
            <AddButton text="Inscrire un élève" onOpen={setIsAddOpen} />

            {isAddOpen && <AddStudentPopup onClose={toggleAddPopup} />}
          </div>
        ) : (
          <div className="vide medium">
            Aucune classe en ce moment pour afficher des élèves
          </div>
        )}
      </main>
    </>
  );
};

export default Students;
