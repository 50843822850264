//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken } from "./upload";

const endpoint = "evaluations";
const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function addEvaluation(body, onClose) {
  const reponse = await uploadToken(endpoint, body, headers);

  if (reponse.ok == true) {
    // console.log("Evaluation ajoutée avec succès");
    onClose();
  } else {
    const errorDetails = await reponse.json();
    console.error("Erreur serveur:", reponse.status, errorDetails);
  }
}

export { addEvaluation };
