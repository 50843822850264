

const getData = async (endPoint,token) => {
    const url ="https://educaly.net/api/"
    
    const response = await fetch(url+endPoint, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Erreur de requête : ' + response.status);
    }
  
    return response.json();
  };

  export {getData}