import React from 'react'

const DashboardAmountBoxLarge = ({ icon, title, amount, subtitle, pourcents }) => {
  return (
    <article className="box DashboardAmountBoxLarge">
      <div className="text">
        <div className="icon">{icon}</div>
        <div className="title">{title}</div>
        <div className="amount">{amount} </div>
        <span className="subtitle">{subtitle}</span>
      </div>

      <div className="analytics">
        <div className="pourcents">{pourcents}%</div>
      </div>
    </article>
  )
}

export default DashboardAmountBoxLarge