//import { useNavigate } from "react-router-dom";

import { accountService } from "../service/account.service";
import { uploadToken } from "./upload";


const endpoint ="absence/justifier";
const token = accountService.getToken()


const headers = { 'Authorization': `Bearer ${token}` };



 async  function justifierAbsen(body,onClose){

    const reponse= await uploadToken(endpoint,body,headers);

    if(reponse.ok==true){
        onClose()
        
    }
}

export{justifierAbsen}

