import React, { useContext, useState } from "react";
import DailyCourses from "./DailyCourses";
import { GetEmploiInterAndClasse } from "../../API/react_query/react_query";
import { getWeekDates, sortByStartTime } from "../../utils/fonctions";
import { ClassContext } from "../../contexts/ClassContext";
import Loading from "../../layouts/Loading";
import AddCoursePopup from "./AddCoursePopup";

const WeekPlanning = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [timeRange, setTimeRange] = useState({ start: "", end: "" });
  const [existingSubjects, setExistingSubjects] = useState([]);
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);
  const weekDates = getWeekDates();

  const {
    isError: isErrorEmp,
    isLoading: isLoadingEmp,
    error: errorNote,
    refetch: refetchEmp,
    isFetching: isFetchingEmp,
    data: dataEmp,
  } = GetEmploiInterAndClasse(
    currentClass,
    anneeScol,
    weekDates.monday,
    weekDates.saturday
  );

  if (isLoadingEmp || !dataEmp) {
    return <Loading size="normal" />;
  }

  const openAddCourse = (jour, startTime, endTime, existingSubjects) => {
    setSelectedDay(jour);
    setTimeRange({ start: startTime, end: endTime });
    setExistingSubjects(existingSubjects);
    setIsOpen(true);
  };

  const closeAddCourse = () => {
    setIsOpen(false);
  };

  const getDailyCourses = (day) => {
    return sortByStartTime(
      dataEmp.cours.filter((course) => course.jour === day)
    );
  };

  return (
    <>
      <div className="week-planning">
        <div className="table">
          {["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"].map(
            (day) => (
              <DailyCourses
                key={day}
                openAddCourse={openAddCourse}
                jour={day}
                courses={getDailyCourses(day)}
              />
            )
          )}
        </div>

        {isOpen && (
          <AddCoursePopup
            onClose={closeAddCourse}
            jour={selectedDay}
            timeRange={timeRange}
            existingSubjects={existingSubjects}
            refetchEmp={refetchEmp}
          />
        )}
      </div>
    </>
  );
};

export default WeekPlanning;
