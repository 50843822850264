import React from "react";
import UserBox from "../../components/user/UserBox";
import { IoExitOutline } from "react-icons/io5";
import { GetUserQuery } from "../../API/react_query/react_query";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";
import { deconnexiomn } from "../../API/fonctions/deconnexion";

const MainAdminSection = () => {
  const { isLoading: isLoadingUser, error: errorUser, data } = GetUserQuery();
  const navigate = useNavigate();
  
  if (isLoadingUser || errorUser || !data) {
    return (
      <Loading size="small" />
    )
  }


  return (
    <div className="main__admin">
      <div className="box__user">
        <UserBox user={{ nom_complet: data?.data?.directeur?.nom_complet }} subtitle={data.data.role.description} />

        <div className="exit" title="Déconnexion">
          <IoExitOutline
            onClick={() => { 
              deconnexiomn(navigate)
              
             }}
          />
        </div>
      </div>
    </div>
  );
};

export default MainAdminSection;
