//import { useNavigate } from "react-router-dom";

import { accountService } from "../service/account.service";
import { uploadToken } from "./upload";

let endpoint = "";
const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function adAdmin(body, fonction, onPrev) {
  if (fonction == "2") {
    endpoint = "caissier";
  }
  if (fonction == "1") {
    endpoint = "attache";
  }

  const reponse = await uploadToken(endpoint, body, headers);
  const errorDetails = await reponse.json();
  console.error("Erreur serveur:", reponse.status, errorDetails);

  if (reponse.ok == true) {
    // console.log("RAS")
    onPrev();
  }
}

export { adAdmin };
