import React, { useRef } from "react";
import "../../../assets/css/popupbg.css";

const PopupBg = ({ closeModal, opacity = "transparent", children }) => {
  const popupRef = useRef();

  const closePopupBg = (e) => {
    if (popupRef.current === e.target) {
      closeModal();
    }
  };

  return (
    <div className={"PopupBg " + opacity} ref={popupRef} onClick={closePopupBg}>
      {/* opacity : transparent/zero */}
      {children}
    </div>
  );
};

export default PopupBg;
