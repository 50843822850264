import React, { useState } from "react";
import {
  IoCalendar,
  IoCall,
  IoLocation,
  IoPeople,
  IoPerson,
} from "react-icons/io5";
import EditableInfoBox from "../../components/form/EditableInfoBox";
import { modifEleve } from "../../API/fonctions/modifEleve";
import { motion } from "framer-motion";

const EditableInfosContainer = ({ studentClicked, onClose }) => {
  // console.log("etudiant selectionner ",studentClicked)
  const [studentInfo, setStudentInfo] = useState({
    nomComplet: studentClicked?.nom_complet || "",
    dateNaissance: studentClicked?.date_naissance || "",
    telephone: studentClicked.telephone || "",
    adresse: studentClicked.adresse || "",
    tuteurLienParente: studentClicked.tuteur.lien_parente || "",
    tuteurNomComplet: studentClicked?.tuteur?.nom_complet || "",
    tuteurTelephone: studentClicked.tuteur.telephone || "",
    sexe: studentClicked.sexe || "",
  });
  const [messageErreur, setMessageErreur] = useState("");
  const [isErreur, setIsErreur] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };
  const handleUpdate = (e) => {
    const body = {
      nom_complet: studentInfo.nomComplet,
      sexe: studentInfo.sexe,
      date_naissance: studentInfo.dateNaissance,
      lieu_naissance: studentInfo.lieu_naissance,
      nationalite: studentInfo.nationalite,
      adresse: studentInfo.adresse,
      telephone: studentInfo.telephone,
      email: "test@test.com",
    };

    modifEleve(body, studentClicked.id, onClose, setIsErreur, setMessageErreur);
  };

  return (
    <motion.div
      className="infos"
      initial={{ translateY: "100%" }}
      animate={{ translateY: 0 }}
      exit={{ translateY: "100%" }}
      transition={{ duration: 0.3 }}
    >
      <h2>Modification des informations de l'élève</h2>

      <div className="infos__container">
        <EditableInfoBox
          icon={<IoPerson />}
          title="Nom complet de l'élève"
          value={studentInfo.nomComplet}
          onChange={handleChange}
          name="nomComplet"
        />

        <EditableInfoBox
          icon={<IoCalendar />}
          title="Date de naissance"
          value={studentInfo.dateNaissance}
          onChange={handleChange}
          type="date"
          name="dateNaissance"
        />

        {studentClicked.telephone && (
          <EditableInfoBox
            icon={<IoCall />}
            title="Numéro de téléphone de l'élève"
            value={studentInfo.telephone}
            onChange={handleChange}
            name="telephone"
          />
        )}

        <EditableInfoBox
          icon={<IoLocation />}
          title="Adresse"
          value={studentInfo.adresse}
          onChange={handleChange}
          name="adresse"
        />

        <EditableInfoBox
          icon={<IoPeople />}
          title="Lien de parenté avec le tuteur"
          value={studentInfo.tuteurLienParente}
          onChange={handleChange}
          name="tuteurLienParente"
        />

        <EditableInfoBox
          icon={<IoPerson />}
          title="Nom du tuteur"
          value={studentInfo.tuteurNomComplet}
          onChange={handleChange}
          name="tuteurNomComplet"
        />

        <EditableInfoBox
          icon={<IoCall />}
          title="Numéro de téléphone du tuteur"
          value={studentInfo.tuteurTelephone}
          onChange={handleChange}
          name="tuteurTelephone"
        />

        <EditableInfoBox
          icon={<IoPerson />}
          title="Sexe"
          value={studentInfo.sexe}
          onChange={handleChange}
          name="sexe"
          type="select"
          options={[
            { value: "", label: "Sélectionner" },
            { value: "M", label: "Masculin" },
            { value: "F", label: "Féminin" },
          ]}
        />
      </div>

      <div className="buttons">
        <button className="cancel" onClick={onClose}>
          Annuler
        </button>
        <button className="edit" onClick={handleUpdate}>
          Modifier
        </button>
      </div>
    </motion.div>
  );
};

export default EditableInfosContainer;
