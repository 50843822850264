import React, { useState } from "react";
import UserBox from "../../components/user/UserBox";

const DisplayStudents = ({ students, openModal, filterTerm }) => {
  const filterEmptyMessage = (filterTerm) => {
    if (filterTerm == "M") {
      return "Cette classe n'a pas de garçons";
    } else if (filterTerm == "F") {
      return "Cette classe n'a pas de filles";
    } else {
      return "Cette classe n'a pas d'élèves";
    }
  };

  return (
    <>
      <>
        <div className="display-students">
          {students.map((student, index) => (
            <UserBox
              key={index}
              user={student.eleves}
              subtitle={student.eleves.matricule}
              onClick={openModal}
              withBg={true}
            />
          ))}
        </div>

        {students.length == 0 && (
          <div className="vide small">{filterEmptyMessage(filterTerm)}</div>
        )}
      </>
    </>
  );
};

export default DisplayStudents;
