import React from "react";
import CourseBox from "./CourseBox";

const DailyCourses = ({ openAddCourse, courses, jour }) => {
  const handleAdd = () => {
    let startTime = "08:00";
    let endTime = "10:00";

    if (courses.length > 0) {
      const lastCourse = courses[courses.length - 1];
      startTime = lastCourse.heure_fin;
      endTime = addHours(startTime, 2);
    }

    const existingSubjects = courses.map((course) => course.matiere.id);

    openAddCourse(jour, startTime, endTime, existingSubjects);
  };

  const showAddButton = () => {
    if (courses.length === 0) return true;
    const lastCourse = courses[courses.length - 1];
    const lastEndTime = lastCourse.heure_fin.slice(0, 5); // Remove seconds if present
    return lastEndTime < "20:00";
  };

  return (
    <div className="day">
      <div className="title">{jour}</div>
      {courses.map((course, index) => (
        <CourseBox
          key={index}
          heureDebut={course.heure_debut.slice(0, -3)}
          heureFin={course.heure_fin.slice(0, -3)}
          matiere={course.matiere?.libelle}
        />
      ))}

      {showAddButton() && (
        <button className="add__course" onClick={handleAdd}>
          +
        </button>
      )}
    </div>
  );
};

// Helper function to add hours to a time string
function addHours(timeString, hoursToAdd) {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date(2000, 0, 1, hours, minutes);
  date.setHours(date.getHours() + hoursToAdd);
  return `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;
}

export default DailyCourses;
