//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken } from "./upload";

const endpoint = "inscription";
const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function addEleve(body, onClose, setMessageErrore, setProcess) {
  setProcess(true);
  const reponse = await uploadToken(endpoint, body, headers);

  if (reponse.ok == true) {
    // console.log("eleve ajoutée avec succès");
    onClose();
    setProcess(false);
  } else {
    const errorDetails = await reponse.json();
    console.error("Erreur serveur:", reponse.status, errorDetails);
    setMessageErrore(reponse.status);
    setProcess(false);
  }
}

export { addEleve };
