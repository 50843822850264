import React from "react";
import "../../../assets/css/addbutton.css";
import { motion } from "framer-motion";

const AddButton = ({ text, onOpen }) => {
  return (
    <motion.button
      className="add__button"
      onClick={() => onOpen(true)}
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
      <div className="text">{text}</div>
      <span className="icon">+</span>
    </motion.button>
  );
};

export default AddButton;
