import React from "react";
import { IoNotifications } from "react-icons/io5";
import NotifBox from "./NotifBox";

const NotificationsList = ({ onClose, onDetail }) => {
  return (
    <div className="Notifications__list">
      <h1>
        Informations{" "}
        <span className="icon" onClick={onClose}>
          +
        </span>
      </h1>
      <div className="list">
        <NotifBox onDetail={onDetail} />
        <NotifBox onDetail={onDetail} />
        <NotifBox onDetail={onDetail} />
        <NotifBox onDetail={onDetail} />
        <NotifBox onDetail={onDetail} />
        <NotifBox onDetail={onDetail} />
        <NotifBox onDetail={onDetail} />
        <NotifBox onDetail={onDetail} />
        <NotifBox onDetail={onDetail} />
        <NotifBox onDetail={onDetail} />
      </div>
    </div>
  );
};

export default NotificationsList;
