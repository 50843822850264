import React from "react";
import { IoPricetag } from "react-icons/io5";
import SalaryBox from "./SalaryBox";

const Salaires = () => {
  return (
    <div className="main-content salaires">
      {/* <div className="total">
        <div className="icon">
          <IoPricetag />
        </div>
        <div className="text">
          <div className="title">Total à payer aux professeurs</div>
          <div className="subtitle">pour le mois en cours, pour le moment</div>
        </div>
        <div className="amount">2 345 000 FCFA</div>
      </div> */}

      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
      <SalaryBox />
    </div>
  );
};

export default Salaires;
