import React, { useContext, useState } from "react";
import DropdownList from "../../../components/form/DropdownList";
import PopupForm from "../../../components/ui/modal/PopupForm";
import {
  IoBookmarkOutline,
  IoPersonOutline,
  IoCallOutline,
  IoHomeOutline,
  IoMailOutline,
} from "react-icons/io5";
import InputField from "../../../components/form/InputField";
import { subjects } from "../../../utils/list";
import ErrorBox from "../../../components/ui/ErrorBox"; // Assurez-vous que le chemin est correct
import { ClassContext } from "../../../contexts/ClassContext";
import { accountService } from "../../../API/service/account.service";
import { addProf } from "../../../API/fonctions/addProf";

const AddTeacherPopup = ({ onClose }) => {
  const [fullName, setFullName] = useState();
  const [phoneNumber, setPhoneNumber] = useState(); // État pour le numéro de téléphone
  const [address, setAddress] = useState(); // État pour l'adresse
  const [email, setEmail] = useState(); // État pour l'e-mail
  const [selectedSubjects, setSelectedSubjects] = useState([]); // État pour les matières sélectionnées
  const [error, setError] = useState(""); // État pour gérer les messages d'erreur
  const [process, setProcess] = useState(false);
  const id_ecole = accountService.getEcole();

  const { anneeScol } = useContext(ClassContext);

  const handleSubjectChange = (index, value) => {
    const newSubjects = [...selectedSubjects];
    newSubjects[index] = value;
    setSelectedSubjects(newSubjects);
  };

  const addSubjectDropdown = () => {
    setSelectedSubjects([...selectedSubjects, ""]); // Ajoute un nouvel élément vide pour un nouveau DropdownList
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Vérification des champs requis
    if (
      !fullName ||
      !phoneNumber ||
      !address ||
      !email ||
      selectedSubjects.some((subject) => !subject)
    ) {
      setError("Oups ! Vous avez oublié de remplir certains champs !");
      return;
    } else {
      const body = {
        nom: fullName,
        password: "1234",
        login: phoneNumber,
        email: email,
        addresse: address,
        telephone: phoneNumber,
        image: "image",
        ecole_id: id_ecole,
        annee_id: anneeScol,
        matiere_enseigne: selectedSubjects,
      };

      addProf(body, onClose, setError, setProcess);
    }

    setError(""); // Réinitialiser l'erreur si tout est bon
    // Logique de soumission ici (par exemple, appel API)
  };

  return (
    <PopupForm
      onClose={onClose}
      title="Ajouter un professeur"
      onSubmit={handleSubmit}
    >
      {error && <ErrorBox message={error} />}{" "}
      {/* Affichage du message d'erreur */}
      <InputField
        icon={<IoPersonOutline />}
        type="text"
        setValue={setFullName}
        placeholder="Prénom et nom"
      />
      <InputField
        icon={<IoCallOutline />}
        type="tel"
        setValue={setPhoneNumber}
        placeholder="Numéro de téléphone"
      />
      <InputField
        icon={<IoHomeOutline />}
        type="text"
        setValue={setAddress}
        placeholder="Adresse"
      />
      <InputField
        icon={<IoMailOutline />}
        type="email"
        setValue={setEmail}
        placeholder="E-mail"
      />
      <h3>Matières enseignées</h3>
      {selectedSubjects.map((subject, index) => (
        <DropdownList
          key={index}
          icon={<IoBookmarkOutline />}
          items={subjects}
          setValue={(value) => handleSubjectChange(index, value)}
          placeholder="Sélectionnez une matière"
        />
      ))}
      <button type="button" className="add-field" onClick={addSubjectDropdown}>
        Ajouter une matière
      </button>
    </PopupForm>
  );
};

export default AddTeacherPopup;
