import React, { useEffect, useRef, useState } from "react";

const AverageMark = ({ moyenneNote }) => {
  // Calculer la position en pourcentage
  const position = (moyenneNote.avg / 20) * 100 - 5;
  const getColor = (avg) => {
    if (avg < 7) return "#F44336";
    if ((avg < 13) & (avg > 7)) return "#FF9800";
    return "#4CAf50";
  };

  return (
    <article className="average-mark">
      <h2>Moyenne</h2>
      <div className="marks">
        <span>{moyenneNote.min}/20</span>
        <span>{moyenneNote.max}/20</span>
      </div>
      <div
        className="line"
        style={{
          justifyContent: "flex-start",
          borderBottomColor: getColor(moyenneNote.avg),
        }}
      >
        {/* <div className="dot"></div> */}
        <div
          className="average"
          style={{
            marginLeft: `${position}%`,
            backgroundColor: getColor(moyenneNote.avg),
          }}
        >
          {moyenneNote.avg}
        </div>
        {/* <div className="dot" style={{ marginRight: 0 }}></div> */}
      </div>
    </article>
  );
};

export default AverageMark;
