import React from "react";
import {
  IoAlarmOutline,
  IoCalendarClearOutline,
  IoCalendarOutline,
  IoTimeOutline,
} from "react-icons/io5";
import LineBox from "../../components/ui/LineBox";

const DayPlanBox = ({
  onStop,
  onReport,
  heur_debut,
  heur_fin,
  matier,
  prof,
  commands = false,
  isEvaluation = false,
  id_modif,
  evaluation,
}) => {
  const donnee = {
    id_modif: id_modif,
    heur_debut: heur_debut,
    heur_fin: heur_fin,
  };

  return (
    <LineBox
      icon={<IoTimeOutline />}
      subtitle={heur_debut + " - " + heur_fin}
      title={evaluation == null ? matier : evaluation + " " + matier}
      withBg={evaluation != null}
    >
      {commands && (
        <div className="options" onClick={onStop}>
          <button className="report">
            <div className="icon">
              <IoCalendarClearOutline />
            </div>
            Annuler
          </button>
        </div>
      )}

      {commands && (
        <div className="options">
          <button className="report" onClick={(e) => onReport(donnee)}>
            <div className="icon">
              <IoCalendarOutline />
            </div>
            Reporter
          </button>
        </div>
      )}
    </LineBox>
  );
};

export default DayPlanBox;
