import React, { useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const NotesChart = ({ chartData = [] }) => {
  // // console.log("test 1", chartData)

  return (
    <article className="notes-chart">
      <h2>Proportions des notes</h2>
      <div className="pie chart">
        <div className="chart-container">
          <Pie
            data={{
              labels: ["0 - 7", "8 - 13", "14 - 20"],
              datasets: [
                {
                  label: "Notes des élèves",
                  data: chartData,
                  backgroundColor: ["#F44336", "#FF9800", "#4CAf50"],
                },
              ],
            }}
          />
        </div>
      </div>
    </article>
  );
};

export default NotesChart;
