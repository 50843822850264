import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import fullScreen from "./assets/images/full-screen.png";
import ClassContextProvider from "./contexts/ClassContext";
import {
  Accountability,
  Home,
  Login,
  Missings,
  Notes,
  Payments,
  Students,
  Timeuses,
} from "./pages";
import "./assets/css/index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeContext } from "./contexts/ThemeContext";
import Bulletin from "./pages/notes/Bulletin";
import { IoFolder } from "react-icons/io5";
import EmptyPage from "./components/ui/EmptyPage";
import Subjects from "./pages/subjects/Subjects";
import RolesContext from "./contexts/RolesContext";
import RolesContextPrivider from "./contexts/RolesContext";
import ProtectedRoute from "./contexts/ProtectedRoute";
import Teachers from "./pages/teachers/Teachers";
import Classe from "./pages/classe/Classe";

const queryClient = new QueryClient();

const App = () => {
  const { theme } = useContext(ThemeContext);

  // const handleFullscreen = () => {
  //   if (!document.fullscreenElement) {
  //     document.documentElement
  //       .requestFullscreen()
  //       .then(() => {
  //         // console.log("Le navigateur est en plein écran");
  //       })
  //       .catch((err) => {
  //         console.error(
  //           `Erreur lors du passage en plein écran: ${err.message}`
  //         );
  //       });
  //   } else {
  //     document
  //       .exitFullscreen()
  //       .then(() => {
  //         // console.log("Le navigateur a quitté le mode plein écran");
  //       })
  //       .catch((err) => {
  //         console.error(
  //           `Erreur lors de la sortie du mode plein écran: ${err.message}`
  //         );
  //       });
  //   }
  // };

  return (
    <>
      <div className={"container " + theme}>
        {/* <div className="responsive" onDoubleClick={handleFullscreen}>
        <EmptyPage
          img={fullScreen}
          title="Caly est plus agréable sur un ordinateur en plein écran"
          description="Faites un double-clique pour mettre Caly en plein écran et profitez pleinement d'une interface épurée pour une expérience agréable et sans complexité"
        />
      </div> */}

        <QueryClientProvider client={queryClient}>
          <RolesContextPrivider>
            <ClassContextProvider>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/home" element={<Home />} />

                <Route element={<ProtectedRoute />}>
                  <Route path="/eleves" element={<Students />} />
                  <Route path="/emploi-du-temps" element={<Timeuses />} />
                  <Route path="/absences" element={<Missings />} />
                  <Route path="/notes" element={<Notes />} />
                  <Route path="/paiements" element={<Payments />} />
                  <Route path="/compta" element={<Accountability />} />
                  <Route path="/pointage" element={<Teachers />} />
                  <Route path="/matieres" element={<Subjects />} />
                  <Route path="/classe" element={<Classe />} />
                </Route>
              </Routes>
            </ClassContextProvider>
          </RolesContextPrivider>
        </QueryClientProvider>
      </div>
    </>
  );
};

export default App;
