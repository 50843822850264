export const students = [
  {id: 1, prenom: 'Abdooulaye', nom: 'Sarr', matricule: 'E_23324', sexe:"M"},
  {id: 2, prenom: 'Fatou', nom: 'Diop', matricule: 'E_334324', sexe:"F"},
  {id: 3, prenom: 'Ibrqhima P--ierre', nom: 'Diop', matricule: 'E_23324', sexe:"M"},
  {id: 4, prenom: 'Khady', nom: 'Faye', matricule: 'E_26624', sexe:"F"},
  {id: 5, prenom: 'Ounar', nom: 'Ka', matricule: 'E_66524', sexe:"M"},
  {id: 6, prenom: 'Mouhamed', nom: 'Njie', matricule: 'E_231111', sexe:"M"},
  {id: 7, prenom: 'Jean Lys', nom: 'Pouye', matricule: 'E_23444', sexe:"M"},
  {id: 8, prenom: 'Maimouna', nom: 'Ndiaye', matricule: 'E_5643224', sexe:"F"},
  {id: 9, prenom: 'Yaye Isseu', nom: 'Mbengue', matricule: 'E_2345422', sexe:"F"},
  {id: 10, prenom: 'Serignw Fallou', nom: 'Sylla', matricule: 'E_233324', sexe:"M"},
  {id: 11, prenom: 'Aminata', nom: 'Bane', matricule: 'E_1333434', sexe:"F"},
  {id: 12, prenom: 'Ndeye Astou', nom: 'Ndiaye', matricule: 'E_1133324', sexe:"F"},
  {id: 13, prenom: 'Fatima', nom: 'Ba', matricule: 'E_3343224', sexe:"F"},
  {id: 14, prenom: 'Ousmane', nom: 'Diallo', matricule: 'E_23355', sexe:"M"},
  {id: 15, prenom: 'Abdooul', nom: 'Mbow', matricule: 'E_2335424', sexe:"M"},
  {id: 17, prenom: 'Macky', nom: 'Sall', matricule: 'E_111111', sexe:"M"},
  {id: 18, prenom: 'Aminata', nom: 'Touré', matricule: 'E_11113342', sexe:"F"},
  {id: 19, prenom: 'Idrissa', nom: 'Seck', matricule: 'E_111166531', sexe:"M"},
  {id: 20, prenom: 'Alioune', nom: 'Sall', matricule: 'E_111122', sexe:"M"},
  {id: 21, prenom: 'Ousmane', nom: 'Sonko', matricule: 'E_111144', sexe:"M"},
  {id: 22, prenom: 'Pape Djibril', nom: 'Fall', matricule: 'E_111331', sexe:"M"},
  {id: 23, prenom: 'Abba', nom: 'NoStress', matricule: 'E_111331', sexe:"M"},
  {id: 24, prenom: 'Serigne Ndiaye', nom: 'Diop', matricule: 'E_155331', sexe:"M"},
  {id: 25, prenom: 'Alioune', nom: 'Gassama', matricule: 'E_3451331', sexe:"M"}
];

export const notesList = [
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12},
  {id:1, subject: 'maths', note:12}
];

export const grads = [
  {id:1, classe: '6ème A'},
  {id:2, classe: '6ème B'},
  {id:3, classe: '6ème '},
  {id:4, classe: '6ème C'},
  {id:5, classe: '6ème D'},
  {id:6, classe: '5ème A'},
  {id:7, classe: '5ème B'},
  {id:8, classe: '5ème C'},
  {id:8, classe: '4ème A'},
  {id:9, classe: '4ème B'},
  {id:10, classe: '4ème C'},
  {id:11, classe: '3ème A'},
  {id:12, classe: '3ème B'},
  {id:13, classe: '3ème C'},
  {id:14, classe: '2nde s2A'},
  {id:15, classe: '2nde s2B'},
  {id:16, classe: '2nde s2C'},
  {id:17, classe: '2nde L2A'},
  {id:18, classe: '2nde L2B'},
  {id:19, classe: '2nde L2C'},
  {id:20, classe: '1ère S2A'},
  {id:21, classe: '1ère S2B'},
  {id:22, classe: '1ère S2C'}
];

export const results = {
  devoir1 : [
    {id: 1, note:12, subject: 'Mathématiques', prenom: 'Abdooulaye', nom: 'Sarr', matricule: 'E_23324'},
    {id: 2, note:14, subject: 'Mathématiques', prenom: 'Fatou', nom: 'Diop', matricule: 'E_334324'},
    {id: 3, note:6, subject: 'Philosophie', prenom: 'Ibrqhima Pierre', nom: 'Diop', matricule: 'E_23324'},
    {id: 4, note:18, subject: 'Philosophie', prenom: 'Khady', nom: 'Faye', matricule: 'E_26624'},
    {id: 5, note:20, subject: 'Mathématiques', prenom: 'Ounar', nom: 'Ka', matricule: 'E_66524', sexe:"M"},
    {id: 6, note:12.5, subject: 'Mathématiques', prenom: 'Assane', nom: 'Ndiaye', matricule: 'E_12524', sexe:"M"}
  ],
  devoir2 : [
    {id: 1, note:15, subject: 'Mathématiques', prenom: 'Abdooulaye', nom: 'Sarr', matricule: 'E_23324'},
    {id: 2, note:11, subject: 'Mathématiques', prenom: 'Fatou', nom: 'Diop', matricule: 'E_334324'},
    {id: 3, note:10, subject: 'Anglais', prenom: 'Ibrqhima Pierre', nom: 'Diop', matricule: 'E_23324'},
    {id: 4, note:16.5, subject: 'Anglais', prenom: 'Khady', nom: 'Faye', matricule: 'E_26624'},
    {id: 5, note:13, subject: 'Anglais', prenom: 'Ounar', nom: 'Ka', matricule: 'E_66524', sexe:"M"}
  ],
  composition1 : [],
  devoir3 : [],
  devoir4 : [],
  composition2 : []
}