import React from 'react'
import { IoNotifications } from 'react-icons/io5'

const NotifBox = ({ onDetail }) => {
  return (
    <div className="notif__box" onClick={onDetail}>
        <div className="icon"><IoNotifications /></div>
        <div className="text">
            <h3>Réunion des surveillants <span className="hour">12:45</span></h3>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, error dolorem, tenetur enim et officia, nesciunt repudiandae architecto ullam optio saepe.
            </p>
        </div>
    </div>
  )
}

export default NotifBox