//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken } from "./upload";

const endpoint = "profs";
const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function addProf(body, onClose , setMessageErrore, setProcess) {
  await  setProcess(true)
 const reponse = await uploadToken(endpoint, body, headers);


  if (reponse.ok == true) {
    console.log("profs  ajoutée avec succès");
    onClose();
    setProcess(false)
  }
  else {
    const errorDetails = await reponse.json();
    console.error("Erreur serveur:", reponse.status, errorDetails);
    setMessageErrore(reponse.status)
    setProcess(false)
  }
}

export { addProf };
