//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken, uploadTokenPUT } from "./upload";

const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function modifNote(
  body,
  id_note,
  onClose,
  setIsErreur,
  setMessageErreur
) {
  const endpoint = "notes/" + id_note;
  //  // console.log(body)
  const reponse = await uploadTokenPUT(endpoint, body, headers);
  // // console.log(reponse)
  // const errorDetails = await reponse.json();
  // console.error("Erreur serveur:", reponse.status, errorDetails);

  if (reponse.ok == true) {
    // console.log("la modification fonctionne tres bien")
    onClose();
  } else {
    const errorDetails = await reponse.json();
    console.error("Erreur serveur:", reponse.status, errorDetails);
    setIsErreur(true);
    setMessageErreur(errorDetails);
  }
}

export { modifNote };
