import React, { createContext, useEffect, useState } from "react";
import {
  GetAnnee,
  GetClasseQuery,
  GetUserQuery,
} from "../API/react_query/react_query";
import { getLastYear } from "../utils/fonctions";
import { accountService } from "../API/service/account.service";
import { useNavigate } from "react-router-dom";
import { generateClassName } from "../utils/orderClasses";

export const ClassContext = createContext();

const ClassContextProvider = ({ children }) => {
  const [currentClass, setCurrentClss] = useState(null);
  const [currentClassName, setCurrentClassName] = useState("...");
  const [user, setUser] = useState({});
  const [anneeScol, setAnneeScol] = useState(1);
  const [token, setToken] = useState(accountService.getToken());
  const { isError, isLoading, error, refetch, isFetching, data } =
    GetClasseQuery();
  const {
    isError: isErrorMat,
    isLoading: isLoadingMat,
    error: errorMat,
    refetch: refetchMat,
    isFetching: isFetchingMat,
    data: dataMat,
  } = GetAnnee();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    isLoading: isLoadingUser,
    error: errorUser,
    data: Datauser,
  } = GetUserQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoadingMat) {
      const year = getLastYear(dataMat).id;
      setAnneeScol(year);
    }
  }, [dataMat]);
  useEffect(() => {
    if (!isLoadingUser) {
      setUser(Datauser);
    }
  }, [Datauser]);
  useEffect(() => {
    if (token == null) navigate("/");
  }, [token]);

  useEffect(() => {
    if (!isLoading || data?.classes.length > 0) {
      if (data?.classes.length > 0) {
        setCurrentClss(data?.classes[0]?.id);
        setCurrentClassName(generateClassName(data?.classes[0]));
      } else {
        setCurrentClss(null);
        setCurrentClassName("...");
      }
    }
  }, [data]);

  const changeClass = (classe_id, classe_name) => {
    setCurrentClss(classe_id);
    setCurrentClassName(classe_name);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <ClassContext.Provider
      value={{
        currentClass,
        changeClass,
        currentClassName,
        isMenuOpen,
        toggleMenu,
        anneeScol,
        token,
        user,
        setUser,
      }}
    >
      {children}
    </ClassContext.Provider>
  );
};

export default ClassContextProvider;
