import React, { useState } from 'react'
import PopupBg from '../../components/ui/modal/PopupBg'
import NotificationsList from './NotificationsList';
import NotificationsNew from './NotificationsNew';
import '../../assets/css/notifications.css'
import ShowFullNotif from './ShowFullNotif';
import { IoChatboxOutline } from 'react-icons/io5';

const Notifications = ({ closeModal }) => {
    const [addFormOpened, setAddFormOpened] = useState(false);
    const [notifDetails, setNotifDetails] = useState('');

    const deleteNotifDetails = () => {
        setNotifDetails('');
    }

    const putNotifDetails = () => {
        setNotifDetails('qqch');
    }

    const openAddForm = () => {
        setAddFormOpened(true);
        deleteNotifDetails();
    }

    const closeAddForm = () => {
        setAddFormOpened(false);
    }

  return (
    <PopupBg closeModal={closeModal} opacity='transparent'>
        <div className='Notifications'>
            {!addFormOpened ?
                <NotificationsList onClose={closeModal} onDetail={putNotifDetails} /> :
                <NotificationsNew onPrev={closeAddForm} />
            }
            
            {notifDetails != '' && <ShowFullNotif />}

            {!addFormOpened &&
                <button className="button__add" onClick={openAddForm}>
                    <IoChatboxOutline />
                </button>
            }
        </div>
    </PopupBg>
  )
}

export default Notifications