
//import { useNavigate } from "react-router-dom";
import { upload } from "./upload";
import { accountService } from "../service/account.service";

//import { accountService } from "../service/account.service";
//import { useDispatch } from "react-redux";
//import { setToken } from "./redux/auth.slice";



export function deconnexiomn(navigate) {

    accountService.logOut()
    navigate('/')

    // return data

}