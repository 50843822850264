import React from 'react'

const DashboardAmountBoxMedium = ({ icon, title, amount, pourcents }) => {
  return (
    <article className="box">
        <div className="text">
        <div className="icon">{icon}</div>
        <div className="title">{title}</div>
        <div className="amount">{amount} </div>
        </div>
        <div className="analytics">
        <div className="pourcents">{pourcents}%</div>
        </div>
    </article>
  )
}

export default DashboardAmountBoxMedium