import React, { useContext, useState } from "react";
import PopupForm from "../components/ui/modal/PopupForm";
import {
  IoBookOutline,
  IoCalendarOutline,
  IoPencilOutline,
  IoPricetagOutline,
} from "react-icons/io5";
import DropdownList from "../components/form/DropdownList";
import InputField from "../components/form/InputField";
import { ClassContext } from "../contexts/ClassContext";
import { verifierMensualiteEtInscription } from "../utils/fonctions";
import ErrorBox from "../components/ui/ErrorBox";
import { AddClasse } from "../API/fonctions/AddClasse";
import { accountService } from "../API/service/account.service";

const levelsList = [
  { id: "Sixieme", content: "Sixieme" },
  { id: "Cinquieme", content: "Cinquieme" },
  { id: "Quatrieme", content: "Quatrieme" },
  { id: "Troisieme", content: "Troisieme" },
  { id: "Seconde", content: "Seconde" },
  { id: "Premiere", content: "Premiere" },
  { id: "Terminale", content: "Terminale" },
  { id: "CI", content: "CI" },
  { id: "CP", content: "CP" },
  { id: "CE1", content: "CE1" },
  { id: "CE2", content: "CE2" },
  { id: "CM1", content: "CM1" },
  { id: "CM2", content: "CM2" },
  { id: "Classe d'accueil", content: "Classe d'accueil" },
  { id: "Petite section", content: "Petite section" },
  { id: "Moyenne section", content: "Moyenne section" },
  { id: "Grande section", content: "Grande section" },
];

const series = [
  { id: "L", content: "L" },
  { id: "L1", content: "L1" },
  { id: "L2", content: "L2" },
  { id: "S1", content: "S1" },
  { id: "S2", content: "S2" },
  { id: "S3", content: "S3" },
  { id: "G", content: "G" },
  { id: "T", content: "T" },
];

const lycee = ["Seconde", "Premiere", "Terminale"];

const AddClassPopup = ({ onClose }) => {
  const [level, setLevel] = useState(levelsList[0].id);
  const [serie, setSerie] = useState(series[0].id);
  const [inscription, setInscription] = useState();
  const [mensualite, setMensualite] = useState();
  const { currentClass, anneeScol } = useContext(ClassContext);
  const id_ecole = accountService.getEcole();
  const [error, setError] = useState();
  const [processing, setProcessing] = useState();

  const handleSubmit = () => {
    // if (!level) {
    //   alert("Veuillez sélectionner un niveau et une série.");
    //   return;
    // }
    //alert("Submitted");
    const body = {
      annee_id: anneeScol,
      ecole_id: id_ecole,
      nom: level,
      montant_inscription: inscription,
      montant_mensuel: mensualite,
      serie: lycee.includes(level) ? serie : null,
    };
    if (verifierMensualiteEtInscription(inscription, mensualite)) {
      AddClasse(body, onClose, setError);
    } else {
      setError("mensualite et inscription sont des nombres ");
    }
  };

  return (
    <PopupForm
      onClose={onClose}
      title="Ajouter une classe"
      onSubmit={handleSubmit}
    >
      {error && <ErrorBox message={error} />}
      <DropdownList
        icon={<IoBookOutline />}
        items={levelsList}
        setValue={setLevel}
        placeholder="Choisir un niveau"
      />
      {lycee.includes(level) && (
        <DropdownList
          icon={<IoPencilOutline />}
          items={series}
          // value={serie}
          setValue={setSerie}
          placeholder="Choisir un niveau"
        />
      )}
      <InputField
        icon={<IoPricetagOutline />}
        type="number"
        placeholder="Montant inscription en FCFA"
        value={inscription}
        setValue={setInscription}
      />
      <InputField
        icon={<IoCalendarOutline />}
        type="number"
        placeholder="Montant mensualité en FCFA"
        value={mensualite}
        setValue={setMensualite}
      />
    </PopupForm>
  );
};

export default AddClassPopup;
