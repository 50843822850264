import React from "react";
import "../../../assets/css/mediumactionbutton.css";

const MediumActionButton = ({ icon, text, onClick }) => {
  return (
    <button className="medium-action-button" onClick={onClick}>
      <span className="icon">{icon}</span>
      {text}
    </button>
  );
};

export default MediumActionButton;
