import React from "react";
import Bulletin from "./Bulletin";

const BulletinViewer = ({ onClose }) => {
  return (
    <div className="bulletin__viewer">
      <div className="header">
        <button>Telecharger</button>
        <div className="close" onClick={onClose}>
          +
        </div>
      </div>

      <div className="bulletin">
        {/* <Bulletin /> */}
        Afficher le bulleetin ici ...
      </div>
    </div>
  );
};

export default BulletinViewer;
