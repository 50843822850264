export const formatDate = (
  dateString,
  includeYear = false,
  includeTime = false
) => {
  const date = new Date(dateString);
  const now = new Date();

  // Déterminer la date d'aujourd'hui, d'hier et de demain
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Vérifier si la chaîne contient l'heure
  const hasTime = dateString.includes("T") || dateString.includes(" ");

  // Options pour formater la date si nécessaire
  let options = {
    year: includeYear ? "numeric" : undefined,
    month: "long", // Mois en toutes lettres
    day: "2-digit",
  };

  // Gestion des cas pour aujourd'hui, hier, et demain
  let formattedDate = "";
  if (date.toDateString() === today.toDateString()) {
    formattedDate = "aujourd’hui";
  } else if (date.toDateString() === yesterday.toDateString()) {
    formattedDate = "hier";
  } else if (date.toDateString() === tomorrow.toDateString()) {
    formattedDate = "demain";
  } else {
    // Sinon, formater la date normalement
    formattedDate = date.toLocaleDateString("fr-FR", options);
  }

  // Si la date contient l'heure et includeTime est true
  if (hasTime && includeTime) {
    const formattedTime = date.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    formattedDate += ` à ${formattedTime}`;
  }

  return formattedDate;
};

// Exemples d'utilisation
// console.log(formatDate("2024-09-16T15:12:00", true, true)); // Si aujourd'hui est le 16 septembre : "aujourd’hui à 15:12"
// console.log(formatDate("2024-09-15", false, false)); // Si aujourd'hui est le 16 septembre : "hier"
// console.log(formatDate("2024-09-17T10:30:00", true, true)); // Si aujourd'hui est le 16 septembre : "demain à 10:30"
// console.log(formatDate("2024-09-18", true, false)); // Si aujourd'hui est le 16 septembre : "18 septembre 2024"
