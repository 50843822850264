import React from 'react'

const DashboardFinanceBox = ({ icon, amount, subtitle, scale }) => {
  return (
    <div className="box in">
      <div className="icon">{icon}</div>
      <div className="text">
        <span className="amount">{amount}</span>
        <span className="title">{subtitle}</span>
      </div>
      <div className="evolution">
        {scale}
      </div>
    </div>
  )
}

export default DashboardFinanceBox