import React from "react";
import { IoTimeOutline } from "react-icons/io5";

const CourseBox = ({ heureDebut, heureFin, matiere }) => {
  return (
    <div className="course__box">
      <div className="duration">
        <span className="icon">
          <IoTimeOutline />
        </span>
        {heureDebut} - {heureFin}
      </div>
      <div className="subject">{matiere}</div>
    </div>
  );
};

export default CourseBox;
