import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ClassContext } from "../contexts/ClassContext";
import { navebareFonction, naveBarLiateViaIadUser } from "../data/naveBarLiateViaIadUser";
import {RolesContext} from "../contexts/RolesContext";
//cont { RoleId,setRoleId} = useContext(RolesContext)


const Navbar = () => {
  const { RoleId,setRoleId} = useContext(RolesContext)
  //console.log(RoleId)
  //console.log("role :",RoleId)
  const navList = navebareFonction(RoleId)
  const { toggleMenu, isMenuOpen } = useContext(ClassContext);
  const navigate = useNavigate();

  const handleNavigation = (e, path) => {
    e.preventDefault();
    toggleMenu();
    // alert(path);
    navigate(path);
  };

  return (
    <nav className={isMenuOpen ? "nav active" : "nav"}>
      <div className="top">
        <NavLink to="/home" className="logo">
          C
        </NavLink>
        <div className="close hidden" onClick={toggleMenu}>
          +
        </div>
      </div>

      <div className="links">
        {navList.map((nav, index) => (
          <NavLink
            to={nav.path}
            key={index}
            onClick={(e) => handleNavigation(e, nav.path)}
          >
            <div className="icon default">{nav.defaultIcon}</div>
            <div className="icon active">{nav.activeIcon}</div>

            <span className="full-text text">{nav.text}</span>
            <span className="one-word text">{nav.text.split(" ")[0]}</span>
          </NavLink>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
