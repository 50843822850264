import React, { useState } from 'react'
import { IoArrowBack, IoCalendarOutline, IoChatbox, IoChatboxOutline, IoNotifications, IoNotificationsOutline, IoPeopleOutline, IoTimeOutline } from 'react-icons/io5'
import SelectableBoxes from '../../components/form/SelectableBoxes';

const NotificationsNew = ({ onPrev }) => {
  const selectables = [
    {id: 1, title: 'Y\'aura cours'},
    {id: 2, title: 'Y\'aura pas de cours'},
    {id: 3, title: 'Arret des cours'}
  ]

  const [stopIndex, setStopIndex] = useState(1);

  const handleSelect = id => {
    setStopIndex(id);
  }

  return (
    <div className='Notifications__new'>
      <h1>
        <span className="icon" onClick={onPrev}>
          <IoArrowBack />
          </span>
        Nouvelle annonce
      </h1>

      <form>
        <p className="input__box">
            <div className="icon"><IoNotificationsOutline /></div>
            <input type="text" placeholder='Titre ...' />
        </p>
        
        <p className="input__box text__area">
            <div className="icon"><IoChatboxOutline /></div>
            <textarea name="message" cols="30" rows="3" placeholder='Message...'></textarea>
        </p>

          <p className="input__box">
              <div className="icon"><IoPeopleOutline /></div>
              <select>
                <option value="">à tout le monde</option>
                <option value="">aux professeurs</option>
                <option value="">aux parents et élèves</option>
                <option value="">aux professeurs, parents et élèves</option>
                <option value="">à l'administration</option>
              </select>
          </p>

          {/* <p className="desc">
            Cette annonce implique-t-elle un arret des cours total, partiel pu pas d'arret des cours ?
          </p> */}
          
          <SelectableBoxes boxes={selectables} onSelect={handleSelect} />

          {stopIndex > 1 &&
          <p className="input__box">
              <div className="icon"><IoCalendarOutline /></div>
              <input type="date" value={'2023-07-09'} />
          </p>
          }

          {stopIndex > 2 &&
            <p className="input__box">
                <div className="icon"><IoTimeOutline /></div>
                <input type="time" value={'12:00'} />
            </p>
          }


        <p className="send__box">
            <button>Envoyer</button>
        </p>
    </form>
    </div>
  )
}

export default NotificationsNew