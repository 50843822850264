//import { useNavigate } from "react-router-dom";

import { accountService } from "../service/account.service";
import { uploadToken } from "./upload";

async function PointerUnProf(body, refetchEProf) {
  const endpoint = "pointage/prof";
  const token = accountService.getToken();
  const headers = { Authorization: `Bearer ${token}` };

  const reponse = await uploadToken(endpoint, body, headers);

  if (reponse.ok == true) {
    refetchEProf();
    // console.log("pointage enregisetrer avec succes ")
  }
}

export { PointerUnProf };
