import React, { useContext, useState } from "react";
import UserWithActionBtn from "../../../components/user/UserWithActionBtn";
import AddTeacherPopup from "./AddTeacherPopup";
import AddButton from "../../../components/ui/button/AddButton";
import EditTeacherPopup from "./EditTeacherPopup";
import { ClassContext } from "../../../contexts/ClassContext";
import { GetProfs } from "../../../API/react_query/react_query";
import UserBox from "../../../components/user/UserBox";

const TeachersList = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [profEdit, setProfEdit] = useState();
  const { anneeScol } = useContext(ClassContext);
  const {
    isError: isErrorProf,
    isLoading: isLoadingProf,
    error: errorProf,
    refetch: refetchEProf,
    isFetching: isFetchingProf,
    data: dataProf,
  } = GetProfs(anneeScol);
  const openEdit = (teacher) => {
    setIsEditOpen(true);
    setProfEdit(teacher);
  };

  return (
    <div className="main-content">
      <h3>Liste des professeurs</h3>
      <div className="grid-2">
        {dataProf?.data?.map((prof, index) => (
          <UserBox
            user={prof?.prof}
            subtitle={
              prof?.prof?.matiere_enseigne
                ? prof?.prof?.matiere_enseigne
                    .map((item) => item.trim())
                    .join(", ")
                : "aucune matiere"
            }
            btnText="Modifier"
            btnFn={openEdit}
          />
        ))}
      </div>

      <AddButton text="Nouveau professeur" onOpen={() => setIsAddOpen(true)} />

      {isAddOpen && (
        <AddTeacherPopup
          onClose={() => (setIsAddOpen(false), refetchEProf())}
        />
      )}
      {isEditOpen && (
        <EditTeacherPopup
          onClose={() => setIsEditOpen(false)}
          teacherData={profEdit}
        />
      )}
    </div>
  );
};

export default TeachersList;
