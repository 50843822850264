import React, { useState, useContext } from 'react'
import {Header, Navbar, ClassesList, SubSidebar} from '../../layouts'
import '../../assets/css/timeuses.css'
import DayPlanning from './DayPlanning';
import WeekPlanning from './WeekPlanning';
import StoppingCoursesPopup from './StoppingCoursesPopup';
import { ClassContext } from '../../contexts/ClassContext';
import Evaluations from './Evaluations';

const Timeuses = () => {
  const [ activeSection, setActiveSection ] = useState('Jour');
  const { currentClassName } = useContext(ClassContext);

  const togglePages = page => {
    setActiveSection(page);
  }

  const showSection = () => {
    switch (activeSection) {
      case "Jour":
        return <DayPlanning />
        break;
      case "Semaine":
        return <WeekPlanning />
        break;
    
      default:
        return <Evaluations />
        break;
    }
  }
  
  return (
    <>
      <Navbar />
      <Header title={"Emploi du temps de la "+currentClassName} />
      <ClassesList />

      <main>
        <SubSidebar
            filterList={["Jour", "Semaine", "Evaluations"]}
            dropdown={false}
            filterFn={togglePages}
        />

        <div className="main-content timeuses">
          {showSection()}
      </div>
      </main>
  </>
  )
}

export default Timeuses