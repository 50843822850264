import React, { useContext, useState } from "react";
import EditableInfoBox from "../../components/form/EditableInfoBox";
import {
  IoBookmarkOutline,
  IoBookOutline,
  IoCalendarOutline,
  IoPricetagOutline,
} from "react-icons/io5";
import { ClassContext } from "../../contexts/ClassContext";
import Button from "../../components/ui/button/Button";
import { motion } from "framer-motion";

const EditClassInfos = () => {
  const { currentClass, currentClassName } = useContext(ClassContext);
  const [classInfos, setClassInfos] = useState({
    niveau: currentClass?.niveau,
    dateNaissance: currentClass?.date_naissance || "",
    serie: currentClass.serie || "",
    inscription: currentClass.inscription || 0,
    mensualite: currentClass.mensualite || 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClassInfos((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  return (
    <motion.div
      className="class-infos"
      initial={{ y: "40%" }}
      animate={{ y: 0 }}
      exit={{ scale: 0.5 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="grid-2">
        <div className="part">
          <h3>Modification des informations de la {currentClassName}</h3>

          <EditableInfoBox
            icon={<IoBookOutline />}
            type="select"
            options={[
              { value: "Sixieme", label: "Sixieme" },
              { value: "Cinquieme", label: "Cinquieme" },
              { value: "Quatrieme", label: "Quatrieme" },
              { value: "Troisieme", label: "Troisieme" },
              { value: "Seconde", label: "Seconde" },
              { value: "Premiere", label: "Premiere" },
              { value: "Terminale", label: "Terminale" },
            ]}
            boxType="contained"
            title="Niveau"
            value=" Seconde"
            onChange={handleChange}
            name="niveau"
          />
          <EditableInfoBox
            icon={<IoBookmarkOutline />}
            type="select"
            options={[
              { value: "L", label: "L" },
              { value: "S", label: "S" },
              { value: "L1", label: "L1" },
              { value: "L2", label: "L2" },
              { value: "S1", label: "S1" },
              { value: "S2", label: "S2" },
              { value: "S3", label: "S3" },
              { value: "T", label: "T" },
              { value: "G", label: "G" },
              { value: "STEG", label: "STEG" },
            ]}
            title="Série"
            value=" Série S"
            boxType="contained"
            onChange={handleChange}
            name="serie"
          />
        </div>

        <div className="part">
          <h3>Frais de scolarité</h3>

          <EditableInfoBox
            icon={<IoPricetagOutline />}
            title="Montant de l'inscrption"
            type="number"
            value={45000}
            boxType="contained"
            onChange={handleChange}
            name="inscrption"
          />

          <EditableInfoBox
            icon={<IoCalendarOutline />}
            title="Montant de la mensualité"
            type="number"
            value={20000}
            boxType="contained"
            onChange={handleChange}
            name="mensualite"
          />
        </div>
      </div>
      <div className="buttons">
        <Button text="Enregistrer les modifications" />
      </div>
    </motion.div>
  );
};

export default EditClassInfos;
