import React, { useRef, useState } from "react";
import "../../assets/css/selectableboxes.css";

const SelectableBoxes = ({ boxes, onSelect, children }) => {
  const otherRef = useRef();
  const [openOther, setOpenOther] = useState(false);
  const [boxState, setBoxState] = useState({
    currentId: 1,
    allBoxes: boxes,
  });

  const toggleOpenOther = (id) => {
    id == boxes.length ? setOpenOther(true) : setOpenOther(false);
    openOther && otherRef.focus();
  };

  const toggleActiveBox = (id) => {
    setBoxState({ ...boxState, currentId: id });
    children && toggleOpenOther(id);
    //// console.log("test selection ",id)
    onSelect(id);
    //// console.log("test selection ",id)
  };

  const classSetter = (id) => {
    if (id == boxState.currentId) {
      return "box active";
    } else {
      return "box";
    }
  };
  return (
    <div className="selectable__boxes large">
      {boxes.map((box) => (
        <div
          key={box.id}
          id={box.id}
          className={classSetter(box.id)}
          onClick={() => toggleActiveBox(box.id)}
        >
          {box.title}
        </div>
      ))}
      {openOther && children}
    </div>
  );
};

export default SelectableBoxes;
