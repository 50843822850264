import React from "react";
import "../assets/css/loading.css";

const Loading = ({ size = "norml" }) => {
  return (
    <div className={"loading-container " + size}>
      <div className="loading-bar">
        <div className="loading-indicator"></div>
      </div>
    </div>
  );
};
export default Loading;
