import React from "react";
import "../../assets/css/infobox.css";

const EditableInfoBox = ({
  icon,
  title,
  value,
  onChange,
  type = "text",
  boxType = "outlined", // contained || outlined
  options = [],
  name,
}) => {
  return (
    <div className={"info-box " + boxType}>
      <div className="icon">{icon}</div>
      <div className="text">
        <div className="title">{title}</div>
        {type === "select" ? (
          <select
            className="value form-field"
            value={value}
            onChange={onChange}
            name={name}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            className="value form-field"
            type={type}
            value={value}
            onChange={onChange}
            name={name}
          />
        )}
      </div>
    </div>
  );
};

export default EditableInfoBox;
