import React from 'react'
import '../../assets/css/emptypage.css'

const EmptyPage = ({ img, title, description }) => {
  return (
    <div className='empty__page'>
        <img src={img} alt="" />
        <div className="empty__page__title">{title}</div>
        <div className="empty__page__description">
            {description}
        </div>
    </div>
  )
}

export default EmptyPage