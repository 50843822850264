import React from 'react'
import '../../assets/css/smallactionbutton.css'

const SmallActionButton = ({ text, actionFn }) => {
  return (
    <button
        className='small__action__button'
        onClick={actionFn}
    >
        {text}
      </button>
  )
}

export default SmallActionButton