import React, { useState } from "react";
import { ClassesList, Header, Navbar, SubSidebar } from "../../layouts";
import AllSubjects from "./AllSubjects";
import SubjectsWithExemptions from "./exemptions/SubjectsWithExemptions";
import SubjectsWithChoices from "./choices/SubjectsWithChoices";
import "../../assets/css/subjects.css";

const Subjects = () => {
  const [filter, setFilter] = useState("Toutes");

  const handleFilter = (filter) => {
    setFilter(filter);
  };

  return (
    <>
      <Navbar />
      <Header title="Matières" />
      <ClassesList />

      <main>
        <SubSidebar
          filterList={["Toutes", "A choix", "Avec exemptions"]}
          filterFn={handleFilter}
        />
        <div className="main-content subjects">
          {filter === "Toutes" ? (
            <AllSubjects />
          ) : filter === "A choix" ? (
            <SubjectsWithChoices />
          ) : (
            <SubjectsWithExemptions />
          )}
        </div>
      </main>
    </>
  );
};

export default Subjects;
