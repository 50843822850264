export const getNiveauOrder = (nom) => {
    const order = {
      ci: 1,
      cp: 2,
      ce1: 3,
      ce2: 4,
      cm1: 5,
      cm2: 6,
      sixieme: 7,
      cinquieme: 8,
      quatrieme: 9,
      troisieme: 10,
      seconde: 11,
      premiere: 12,
      terminale: 13,
    };
    return order[nom.toLowerCase()] || 14; // Default to 14 if not found
  };
  
 export const formatNiveauNom = (nom) => {
    switch (nom.toLowerCase()) {
      case "ci":
        return "CI";
      case "cp":
        return "CP";
      case "ce1":
        return "CE1";
      case "ce2":
        return "CE2";
      case "cm1":
        return "CM1";
      case "cm2":
        return "CM2";
      case "sixieme":
        return "6ème";
      case "cinquieme":
        return "5ème";
      case "quatrieme":
        return "4ème";
      case "troisieme":
        return "3ème";
      case "seconde":
        return "2nde";
      case "premiere":
        return "1ère";
      case "terminale":
        return "Tle";
      default:
        return nom; // Return original name if not in the list
    }
  };
  
export const generateClassName = (item) => {
    return `${item?.niveau.nom} ${item?.niveau.serie ?? ""}${item?.nom}`;
  };
  
  // Nouvelle fonction pour comparer les classes
   export const compareClasses = (a, b) => {
    const orderA = getNiveauOrder(a.niveau);
    const orderB = getNiveauOrder(b.niveau);
    
    if (orderA !== orderB) {
      return orderA - orderB;
    }
    
    // Si les niveaux sont identiques, trier par le nom complet de la classe
    return a.name.localeCompare(b.name);
  };