import React, { useContext, useState } from "react";
import {
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoPerson,
} from "react-icons/io5";
import { Header, Navbar, ClassesList, SubSidebar } from "../../layouts";
import UserBox from "../../components/user/UserBox";
// import Paid from '../../assets/Paid.png';
import "../../assets/css/payments.css";
import EspecePayment from "./EspecePayment";
import PaymentsList from "./PaymentsList";
import { students } from "../../data/List";
import { ClassContext } from "../../contexts/ClassContext";
import { GetEleve, GetPaiementClasse } from "../../API/react_query/react_query";
import Loading from "../../layouts/Loading";

const Payments = () => {
  const [espece, setEspece] = useState(true);
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);

  const togglePages = (page) => {
    page != "Liste" ? setEspece(true) : setEspece(false);
  };

  return (
    <>
      <Navbar />
      <Header title={"Paiements de la " + currentClassName} />
      <ClassesList />

      <main>
        <SubSidebar
          filterList={["Payer en espèce", "Liste"]}
          dropdown={false}
          filterFn={togglePages}
          withSearchBar={false}
        />

        {espece ? <EspecePayment /> : <PaymentsList />}
      </main>
    </>
  );
};

export default Payments;
