import React from "react";
import "../../assets/css/errorbox.css";

const ErrorBox = ({ codeError, message = "" }) => {
  const showErrorMessage = (code) => {
    if (message) {
      return message;
    }{
    switch (code) {
      case 401:
        return "Le numéro de téléphone ou le mot de passe est incorrect";
        break;
      case 1:
        return "Veuillez remplir tous les champs.";
        break;


      default:
        return "Veuillez remplir tous les champs.";
        break;

    }}
  };
  return <div className="error__box">{showErrorMessage(codeError)}</div>;
};

export default ErrorBox;
