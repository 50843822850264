let saveToken = (token) => {
  localStorage.setItem("token", token);
};

let saveEcole = (ecole) => {
  localStorage.setItem("ecole", ecole);
};

let logOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("ecole");
  localStorage.removeItem("roleUser");
  localStorage.removeItem("NomComplet");
  localStorage.removeItem("Fonction");
};

let isLoged = () => {
  let token = localStorage.getItem("token");
  return !!token; // retourne true si le token n'est pas vide, false sinon
};

let getToken = () => {
  let token = localStorage.getItem("token");
  return token;
};

let getEcole = () => {
  let ecole = localStorage.getItem("ecole");
  return ecole;
};

let getIdEecole = () => {
  let id = localStorage.getItem("id");
  return id;
};

let saveId = (id) => {
  localStorage.setItem("id", id);
};

let saveRoleUser=(id)=>{
  localStorage.setItem("roleUser", id);
}
let getRoleUser=()=>{
  let id = localStorage.getItem("roleUser");
  return id;
}

let saveNomComplet=(id)=>{
  localStorage.setItem("NomComplet", id);
}
let getNomComplet=()=>{
  let id = localStorage.getItem("NomComplet");
  return id;
}
let saveFonction=(id)=>{
  localStorage.setItem("Fonction", id);
}
let getFonction=()=>{
  let id = localStorage.getItem("Fonction");
  return id;
}
export const accountService = {
  getRoleUser,
  saveRoleUser,
  saveToken,
  logOut,
  isLoged,
  saveId,
  getToken,
  getIdEecole,
  saveEcole,
  getEcole,
  saveFonction,
  getFonction,
  saveNomComplet,
  getNomComplet
};
