import React from "react";
import "../../assets/css/userbox.css";
import { IoPerson } from "react-icons/io5";

const ElementBox = ({ icon, subtitle, element, onClick, withBg = false }) => {
  return (
    <div
      className={withBg ? "user element bg" : "user element"}
      onClick={() => onClick(element)}
    >
      <div className="icon-box">{icon}</div>
      <div className="details">
        <div className="name">{element.nom_complet}</div>
        <span className="subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

export default ElementBox;
