import React, { useContext, useState } from "react";
import {
  IoArrowBack,
  IoChevronForward,
  IoDocumentsOutline,
  IoPrint,
} from "react-icons/io5";
import AverageMark from "./AverageMark";
import NotesChart from "./NotesChart";
import { ClassContext } from "../../contexts/ClassContext";

const NotesStats = ({
  chartData,
  bulletins,
  afficherBulletin,
  moyenneNote,
  setSwitched,
}) => {
  const { currentClassName } = useContext(ClassContext);
  const [generated, setGenerated] = useState(false);
  const generate = () => {
    setGenerated(true);
  };
  return (
    <div className="stats second-page">
      <h3>
        <div className="switch-btn" onClick={() => setSwitched(false)}>
          <span className="icon">
            <IoArrowBack />
          </span>
          <span className="text">Notes</span>
        </div>
        Statistiques relatives aux notes
      </h3>

      {
        // bulletins && <div className="buttons">
        //     {generated ?
        //         (<button className="print">
        //             <span className="icon"><IoPrint /></span>
        //             Imprimer les bulletins
        //         </button>) :
        //         (<button className="generate" onClick={afficherBulletin}>
        //         <span className="icon"><IoDocumentsOutline /></span>
        //         Générer les bulletins
        //     </button>)
        //     }
        // </div>
      }

      <AverageMark moyenneNote={moyenneNote} />
      <NotesChart chartData={chartData} />
    </div>
  );
};

export default NotesStats;
