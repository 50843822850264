import {
    IoAlarm,
    IoAlarmOutline,
    IoAnalytics,
    IoAnalyticsOutline,
    IoBagRemove,
    IoBagRemoveOutline,
    IoBookmark,
    IoBookmarkOutline,
    IoCalendar,
    IoCalendarOutline,
    IoDocumentText,
    IoDocumentTextOutline,
    IoFingerPrint,
    IoFingerPrintOutline,
    IoHome,
    IoHomeOutline,
    IoPeople,
    IoPeopleOutline,
  } from "react-icons/io5";
export const naveBarLiateViaIadUser = [
[
    {
        activeIcon: <IoHome />,
        defaultIcon: <IoHomeOutline />,
        path: "/home",
        text: "Accueil",
      },
],
[
    {
        activeIcon: <IoHome />,
        defaultIcon: <IoHomeOutline />,
        path: "/home",
        text: "Accueil",
      },
      {
        activeIcon: <IoPeople />,
        defaultIcon: <IoPeopleOutline />,
        path: "/eleves",
        text: "Elèves",
      },
      {
        activeIcon: <IoCalendar />,
        defaultIcon: <IoCalendarOutline />,
        path: "/emploi-du-temps",
        text: "Emploi du temps",
      },
      {
        activeIcon: <IoAlarm />,
        defaultIcon: <IoAlarmOutline />,
        path: "/absences",
        text: "Absences et retards",
      },
      {
        activeIcon: <IoDocumentText />,
        defaultIcon: <IoDocumentTextOutline />,
        path: "/notes",
        text: "Notes et bulletins",
      },
      {
        activeIcon: <IoBagRemove />,
        defaultIcon: <IoBagRemoveOutline />,
        path: "/paiements",
        text: "Paiements",
      },
      {
        activeIcon: <IoBookmark />,
        defaultIcon: <IoBookmarkOutline />,
        path: "/matieres",
        text: "Matières",
      },
      {
        activeIcon: <IoFingerPrint />,
        defaultIcon: <IoFingerPrintOutline />,
        path: "/pointage",
        text: "Professeurs ",
      },
      {
        activeIcon: <IoAnalytics />,
        defaultIcon: <IoAnalyticsOutline />,
        path: "/compta",
        text: "Comptabilité",
      },
],
[
    {
        activeIcon: <IoHome />,
        defaultIcon: <IoHomeOutline />,
        path: "/home",
        text: "Accueil",
      },
      {
        activeIcon: <IoPeople />,
        defaultIcon: <IoPeopleOutline />,
        path: "/eleves",
        text: "Elèves",
      },
      {
        activeIcon: <IoCalendar />,
        defaultIcon: <IoCalendarOutline />,
        path: "/emploi-du-temps",
        text: "Emploi du temps",
      },
      {
        activeIcon: <IoAlarm />,
        defaultIcon: <IoAlarmOutline />,
        path: "/absences",
        text: "Absences et retards",
      },
      {
        activeIcon: <IoDocumentText />,
        defaultIcon: <IoDocumentTextOutline />,
        path: "/notes",
        text: "Notes et bulletins",
      },
      {
        activeIcon: <IoBagRemove />,
        defaultIcon: <IoBagRemoveOutline />,
        path: "/paiements",
        text: "Paiements",
      },
      {
        activeIcon: <IoBookmark />,
        defaultIcon: <IoBookmarkOutline />,
        path: "/matieres",
        text: "Matières",
      },
      {
        activeIcon: <IoFingerPrint />,
        defaultIcon: <IoFingerPrintOutline />,
        path: "/pointage",
        text: "Pointage des profs",
      },
    //   {
    //     activeIcon: <IoAnalytics />,
    //     defaultIcon: <IoAnalyticsOutline />,
    //     path: "/compta",
    //     text: "Comptabilité",
    //   },
],
[
{
    activeIcon: <IoHome />,
    defaultIcon: <IoHomeOutline />,
    path: "/home",
    text: "Accueil",
  },
//   {
//     activeIcon: <IoPeople />,
//     defaultIcon: <IoPeopleOutline />,
//     path: "/eleves",
//     text: "Elèves",
//   },
  {
    activeIcon: <IoCalendar />,
    defaultIcon: <IoCalendarOutline />,
    path: "/emploi-du-temps",
    text: "Emploi du temps",
  },
//   {
//     activeIcon: <IoAlarm />,
//     defaultIcon: <IoAlarmOutline />,
//     path: "/absences",
//     text: "Absences et retards",
//   },
//   {
//     activeIcon: <IoDocumentText />,
//     defaultIcon: <IoDocumentTextOutline />,
//     path: "/notes",
//     text: "Notes et bulletins",
//   },
  {
    activeIcon: <IoBagRemove />,
    defaultIcon: <IoBagRemoveOutline />,
    path: "/paiements",
    text: "Paiements",
  },
//   {
//     activeIcon: <IoBookmark />,
//     defaultIcon: <IoBookmarkOutline />,
//     path: "/matieres",
//     text: "Matières",
//   },
  {
    activeIcon: <IoFingerPrint />,
    defaultIcon: <IoFingerPrintOutline />,
    path: "/pointage",
    text: "Pointage des profs",
  },
  {
    activeIcon: <IoAnalytics />,
    defaultIcon: <IoAnalyticsOutline />,
    path: "/compta",
    text: "Comptabilité",
  },
],
[
  {
      activeIcon: <IoHome />,
      defaultIcon: <IoHomeOutline />,
      path: "/home",
      text: "Accueil",
    },
  //   {
  //     activeIcon: <IoPeople />,
  //     defaultIcon: <IoPeopleOutline />,
  //     path: "/eleves",
  //     text: "Elèves",
  //   },
    {
      activeIcon: <IoCalendar />,
      defaultIcon: <IoCalendarOutline />,
      path: "/emploi-du-temps",
      text: "Emploi du temps",
    },
  //   {
  //     activeIcon: <IoAlarm />,
  //     defaultIcon: <IoAlarmOutline />,
  //     path: "/absences",
  //     text: "Absences et retards",
  //   },
  //   {
  //     activeIcon: <IoDocumentText />,
  //     defaultIcon: <IoDocumentTextOutline />,
  //     path: "/notes",
  //     text: "Notes et bulletins",
  //   },
    {
      activeIcon: <IoBagRemove />,
      defaultIcon: <IoBagRemoveOutline />,
      path: "/paiements",
      text: "Paiements",
    },
  //   {
  //     activeIcon: <IoBookmark />,
  //     defaultIcon: <IoBookmarkOutline />,
  //     path: "/matieres",
  //     text: "Matières",
  //   },
    // {
    //   activeIcon: <IoFingerPrint />,
    //   defaultIcon: <IoFingerPrintOutline />,
    //   path: "/pointage",
    //   text: "Pointage des profs",
    // },
    // {
    //   activeIcon: <IoAnalytics />,
    //   defaultIcon: <IoAnalyticsOutline />,
    //   path: "/compta",
    //   text: "Comptabilité",
    // },
]
];

 const naveBarLiateViaIadUserDirecteur = [
      {
          activeIcon: <IoHome />,
          defaultIcon: <IoHomeOutline />,
          path: "/home",
          text: "Accueil",
        },
        {
          activeIcon: <IoPeople />,
          defaultIcon: <IoPeopleOutline />,
          path: "/eleves",
          text: "Elèves",
        },
        {
          activeIcon: <IoCalendar />,
          defaultIcon: <IoCalendarOutline />,
          path: "/emploi-du-temps",
          text: "Emploi du temps",
        },
        {
          activeIcon: <IoAlarm />,
          defaultIcon: <IoAlarmOutline />,
          path: "/absences",
          text: "Absences et retards",
        },
        {
          activeIcon: <IoDocumentText />,
          defaultIcon: <IoDocumentTextOutline />,
          path: "/notes",
          text: "Notes et bulletins",
        },
        {
          activeIcon: <IoBagRemove />,
          defaultIcon: <IoBagRemoveOutline />,
          path: "/paiements",
          text: "Paiements",
        },
        {
          activeIcon: <IoBookmark />,
          defaultIcon: <IoBookmarkOutline />,
          path: "/matieres",
          text: "Matières",
        },
        {
          activeIcon: <IoFingerPrint />,
          defaultIcon: <IoFingerPrintOutline />,
          path: "/pointage",
          text: "Professeurs ",
        },
        {
          activeIcon: <IoAnalytics />,
          defaultIcon: <IoAnalyticsOutline />,
          path: "/compta",
          text: "Comptabilité",
        },
  ]
 const naveBarLiateViaIadUserAttache = [
    {
        activeIcon: <IoHome />,
        defaultIcon: <IoHomeOutline />,
        path: "/home",
        text: "Accueil",
      },
      {
        activeIcon: <IoPeople />,
        defaultIcon: <IoPeopleOutline />,
        path: "/eleves",
        text: "Elèves",
      },
      {
        activeIcon: <IoCalendar />,
        defaultIcon: <IoCalendarOutline />,
        path: "/emploi-du-temps",
        text: "Emploi du temps",
      },
      {
        activeIcon: <IoAlarm />,
        defaultIcon: <IoAlarmOutline />,
        path: "/absences",
        text: "Absences et retards",
      },
      {
        activeIcon: <IoDocumentText />,
        defaultIcon: <IoDocumentTextOutline />,
        path: "/notes",
        text: "Notes et bulletins",
      },
      {
        activeIcon: <IoBookmark />,
        defaultIcon: <IoBookmarkOutline />,
        path: "/matieres",
        text: "Matières",
      },
      {
        activeIcon: <IoFingerPrint />,
        defaultIcon: <IoFingerPrintOutline />,
        path: "/pointage",
        text: "Professeurs ",
      },
]
const naveBarLiateViaIadUserCaissier = [
  {
      activeIcon: <IoHome />,
      defaultIcon: <IoHomeOutline />,
      path: "/home",
      text: "Accueil",
    },
    {
      activeIcon: <IoBagRemove />,
      defaultIcon: <IoBagRemoveOutline />,
      path: "/paiements",
      text: "Paiements",
    },
    {
      activeIcon: <IoAnalytics />,
      defaultIcon: <IoAnalyticsOutline />,
      path: "/compta",
      text: "Comptabilité",
    },

]

export function navebareFonction(id) {
  switch (id) {
      case "1":
          return naveBarLiateViaIadUserDirecteur;
      case "4":
          return naveBarLiateViaIadUserAttache;
      case "6":
          return naveBarLiateViaIadUserCaissier;
      default:
          return [];
  }
}