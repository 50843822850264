import React from "react";
import ElementBox from "./ElementBox";
import SmallActionButton from "../ui/SmallActionButton";
import "../../assets/css/userwithactionbtn.css";

const ElementWithActionBtn = ({ element, btn, secondBtn }) => {
  return (
    <div className="user__with__action__btn element__with__action__btn">
      <ElementBox
        icon={element.icon}
        element={element.element}
        subtitle={element.subtitle}
      />

      <div className="group__btns">
        <SmallActionButton
          text={btn.text}
          actionFn={() => btn.fn(element.element)}
        />

        <div
          className="btn__icon"
          onClick={() => secondBtn.fn(element.element)}
        >
          {secondBtn.icon}
        </div>
      </div>
    </div>
  );
};

export default ElementWithActionBtn;
