import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logoCaly from "../../assets/images/logo_caly_for_light_mode.png";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    padding: 30,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    width: 50,
    height: 50,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    paddingBottom: 5,
    marginBottom: 5,
  },
  column: {
    flex: 1,
  },
  bold: {
    fontWeight: "bold",
  },
});

const Bulletin = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={logoCaly} />
        <Text>Année scolaire : 2023/2024</Text>
      </View>

      <Text style={styles.title}>Bulletin Scolaire</Text>

      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.column}>Nom : </Text>
          <Text style={[styles.column, styles.bold]}>DIAGNE</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Prénom : </Text>
          <Text style={[styles.column, styles.bold]}>FATOU</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Classe : </Text>
          <Text style={[styles.column, styles.bold]}>SECONDE L2B</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.bold}>Résultats :</Text>
        <View style={styles.row}>
          <Text style={styles.column}>Matière</Text>
          <Text style={styles.column}>Note</Text>
          <Text style={styles.column}>Appréciation</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Mathématiques</Text>
          <Text style={styles.column}>15/20</Text>
          <Text style={styles.column}>Très bien</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Français</Text>
          <Text style={styles.column}>14/20</Text>
          <Text style={styles.column}>Bien</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Histoire-Géo</Text>
          <Text style={styles.column}>13/20</Text>
          <Text style={styles.column}>Assez bien</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.bold}>Moyenne générale : 14/20</Text>
        <Text style={styles.bold}>Rang : 5/30</Text>
      </View>

      <Text style={styles.bold}>Commentaire général :</Text>
      <Text>Bon trimestre dans l'ensemble. Continuez vos efforts !</Text>
    </Page>
  </Document>
);

export default Bulletin;
