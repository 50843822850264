import React from "react";
import { IoNotifications } from "react-icons/io5";

const NotifCard = ({ title, subtitle, detail }) => {
  return (
    <div className="notif__crd">
      <div className="icon">
        <IoNotifications />
      </div>

      <div className="notif__crd__text">
        <span className="notif__crd__title">{title}</span>
        <span className="notif__crd__desc">{subtitle}</span>
      </div>
      <div className="notif__crd__date">{detail}</div>
    </div>
  );
};

export default NotifCard;
