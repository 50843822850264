import React, { useState } from "react";
import PopupBg from "../../components/ui/modal/PopupBg";
import {
  IoCheckmarkCircle,
  IoEllipseOutline,
  IoTimeOutline,
} from "react-icons/io5";
import ToggleSelectBox from "../../components/form/ToggleSelectBox";
import MissingCourseBox from "./MissingCourseBox";
import { nombreAbs } from "../../utils/fonctions";
import { justifierAbsen } from "../../API/fonctions/justifierAbsen";
import { formatDate } from "../../utils/dateConversion";
import PopupForm from "../../components/ui/modal/PopupForm";

const JustificationPopup = ({ onClose, studentId }) => {
  // LISTE DES ID DE COURS A ENVOYER AU BACK-END POUR LES JUSTIFIER

  // // console.log("leleves ",studentId)
  const [idList, setIdList] = useState([]);
  const nomComplet = studentId[0].items[0].eleve.nom_complet;
  const nombreAbse = nombreAbs(studentId);

  const justifier = (e) => {
    e.preventDefault();

    const body = {
      absence: idList,
      motif: "okk",
    };

    const repons = justifierAbsen(body, onClose);
  };

  const addToList = (id) => {
    const newList = [...idList];
    newList.push(id);
    setIdList(newList);
  };

  const removeToList = (id) => {
    setIdList((prevList) => prevList.filter((element) => element !== id));
  };

  const handleToggle = (id, checked) => {
    if (!checked) {
      addToList(id);
    } else {
      removeToList(id);
    }
    // // console.log("test iddddddddddddddddddddddddddddddd",id)
  };

  return (
    <PopupForm
      title="Justifier les absences"
      onClose={onClose}
      onSubmit={(e) => justifier(e)}
    >
      <div className="fields-container">
        <div className="scrollable__list">
          <div className="info__box">
            <div className="info__box__title">
              {nomComplet} - {nombreAbse} absence(s)
            </div>
            <div className="info__box__text">
              Sélectionnez les absences que vous souhaitez justifier
            </div>
          </div>

          {studentId.map((item) => (
            <div>
              <h3>{formatDate(item.date_absence)}</h3>

              {item.items.map((absence) => (
                <MissingCourseBox
                  //    key={absence.id}  {/* Utilisez une clé unique pour chaque composant */}
                  id={absence.id}
                  matiere={absence.cours.matiere.libelle}
                  horaire={`${absence.cours.heure_debut.slice(
                    0,
                    -3
                  )} - ${absence.cours.heure_fin.slice(0, -3)}`}
                  toggleFn={handleToggle}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </PopupForm>
  );
};

export default JustificationPopup;
