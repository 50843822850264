import React from "react";
import InfoBox from "../../components/ui/box/InfoBox";
import {
  IoCalendar,
  IoCall,
  IoDownload,
  IoLocation,
  IoPencil,
  IoPeople,
  IoPerson,
} from "react-icons/io5";
import { formatDate } from "../../utils/dateConversion";
import { formatPhoneNumber } from "../../utils/fonctions";
import { Link } from "react-router-dom";
import MediumActionButton from "../../components/ui/button/MediumActionButton";

const InfosContainer = ({ studentClicked, onEdit }) => {
  return (
    <div className="infos">
      <h2>Informations de l'élève</h2>

      <div className="infos__container">
        <InfoBox
          icon={<IoCalendar />}
          title="Date de naissance"
          value={formatDate(studentClicked?.date_naissance, true)}
        />

        {studentClicked.telephone && (
          <InfoBox
            icon={<IoCall />}
            title="Numéro de téléphone de l'élève"
            value={formatPhoneNumber(studentClicked.telephone)}
          />
        )}

        <InfoBox
          icon={<IoLocation />}
          title="Adresse"
          value={studentClicked.adresse}
        />

        {studentClicked.tuteur.lien_parente && (
          <InfoBox
            icon={<IoPeople />}
            title="Tuteur"
            value={studentClicked.tuteur.lien_parente}
          />
        )}

        <InfoBox
          icon={<IoPerson />}
          title="Nom du tuteur"
          value={studentClicked?.tuteur?.nom_complet}
        />

        <InfoBox
          icon={<IoCall />}
          title="Numéro de téléphone du tuteur"
          value={formatPhoneNumber(studentClicked.tuteur.telephone)}
        />
      </div>

      <h2 className="title-documents">Documents de l'élève</h2>

      <div className="infos__container">
        <div className="download-box">
          <div className="icon">
            <IoDownload />
          </div>
          <Link>Certificat de scolarité</Link>
        </div>

        <div className="download-box">
          <div className="icon">
            <IoDownload />
          </div>
          <Link>Certificat de fréquentation</Link>
        </div>
      </div>

      <MediumActionButton
        icon={<IoPencil />}
        text="Modifier les informations"
        onClick={onEdit}
      />
    </div>
  );
};

export default InfosContainer;
