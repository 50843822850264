import React from "react";
import "../../../assets/css/button.css";

const Button = ({ type, text, onClick }) => {
  return (
    <button className={"button-component  " + type} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
