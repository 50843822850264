import React from "react";
import "../../../assets/css/infobox.css";

const InfoBox = ({ icon, title, value, type = "outline" }) => {
  // type = outline || contained
  return (
    <div className={"info-box " + type}>
      <div className="icon">{icon}</div>
      <div className="text">
        <div className="title">{title}</div>
        <div className="value">{value}</div>
      </div>
    </div>
  );
};

export default InfoBox;
