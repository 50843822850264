import React from "react";

const TimeRangeInput = ({ icon, value, onChange }) => {
  const handleChange = (e) => {
    const { name, value: newValue } = e.target;
    onChange((prev) => ({ ...prev, [name]: newValue }));
  };

  return (
    <div className="input__box intervals">
      <div className="icon">{icon}</div>
      <input
        type="time"
        step="60"
        name="start"
        value={value.start}
        onChange={handleChange}
      />
      <span className="dash">-</span>
      <input
        type="time"
        step="60"
        name="end"
        value={value.end}
        onChange={handleChange}
      />
    </div>
  );
};

export default TimeRangeInput;
