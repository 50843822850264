import React from 'react'
import { useState } from 'react';
import { createContext } from 'react';

export const ThemeContext = createContext();

const ThemeContextProvider = props => {
    const [ theme, setTheme ] = useState(localStorage.getItem('theme') || "dark");

    const updateTheme = () => {
      var colorTheme = "";
      theme == "dark" ? colorTheme = "light" : colorTheme = "dark";
      setTheme(colorTheme);
      localStorage.setItem('theme', colorTheme);
    }

  return (
    <ThemeContext.Provider value={{
            theme,
            updateTheme
    }}>
        {props.children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider