import React from "react";
import { useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import UserBox from "../../components/user/UserBox";
import { renamePrenomToNomComplet } from "../../utils/fonctions";

const AdminsSection = ({ categorie, list }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prev) => !isOpen);
  };

  return (
    <div className="admins__section">
      <div className="dropdown__title" onClick={toggleOpen}>
        <div className="icon">
          {isOpen ? <IoChevronUp /> : <IoChevronDown />}
        </div>

        <h3>{categorie}</h3>
      </div>

      {isOpen && (
        <>
          {list.length > 0 ? (
            <div className="admins__list">
              {list.map((user) => (
                <UserBox key={user.id} user={renamePrenomToNomComplet(user)} subtitle={user.login} />
              ))}
            </div>
          ) : (
            <div className="admins__list">
              <div className="empty-admins">
                Vos {categorie.toLowerCase()} s'affichent ici ...
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminsSection;
